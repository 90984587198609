import React from "react";
import PropTypes from "prop-types";
import { withPrefix } from "gatsby"

// icons
import anchor from "../images/3FO_BG_Links.svg";
import anchor_yellow from "../images/3FO_BG_Links_yellow.svg";

function TypographySection({ copyLink }) {
  return (
    <section className="text-left mt-20 w-full" id="typography">
      <div className="bg-white sticky flex z-10 top-0">
        <h1 className="heading-1" tabIndex="0">
        typography
        </h1>
        <img
          className="anchor-icon"
          onClick={() => copyLink("#typography")}
          src={anchor}
          onMouseEnter={e => (e.currentTarget.src = anchor_yellow)}
          onMouseLeave={e => (e.currentTarget.src = anchor)}
          alt="copy link"
        />
      </div>
      <p
        className="font-second_font font-light text-2xl my-4 md:w-5/6"
        tabIndex="0"
      >
        Our brand fonts are simple, bold, and supportive of our values. Our primary font, Avant Garde Gothic, is approachable with it's round geometric letterforms and resolute with its bold weight. It's used for all headlines. Aktiv Grotesk is our supportive font with good legibility used for body copy only.
      </p>

      <h2
        className="text-heading_color font-primary_font text-2xl inline-block mt-6"
        tabIndex="0"
      >
        primary font
      </h2>
      <figure className="leading-none">
        <p className="font-primary_font text-second_color bg-s1 text-6xl md:text-10xl tracking-tighter pl-8 pt-8 pb-8 mt-1">
        Aa display headline.
        </p>
        <figcaption className="font-second_font font-light text-sm uppercase mt-2">
        Aa display headline.
        </figcaption>
      </figure>
      <figure className="leading-none">
        <p className="font-primary_font text-second_color bg-s1 text-3xl md:text-4xl tracking-tighter pl-8 pt-8 pb-8 mt-12">
        Aa secondary headline.
        </p>
        <figcaption className="font-second_font font-light text-sm uppercase mt-2">
        SECONDARY HEADLINES, AVANTE GARDE GOTHIC, 42PT, LOWERCASE
        </figcaption>
      </figure>

      <h2
        className="text-heading_color font-primary_font text-2xl inline-block mt-16"
        tabIndex="0"
      >
        secondary font
      </h2>
      <figure className="leading-tight mb-4">
        <p className="leading-normal font-second_font bg-s1 text-lg font-light md:pr-48 pl-8 pt-8 pb-8 pr-8 my-2">
        Aa paragraph text, gravida mi nibh, a auctor enim tempor non. Sed non ultrices neque. Nunc lectus lorem, condimentum vitae sem sit amet, lobortis ullamcorper nisi. Nam odio lorem, varius vel lacus id, ullamcorper suscipit arcu. Praesent vel diam nec augue porttitor molestie. Nam sed justo ac est sodales hendrerit quis nec eros. Mauris non massa vitae nunc tincidunt egestas. Vivamus enim nulla, pellentesque id pharetra sed, lacinia at nulla. Maecenas nec suscipit libero. Nunc tempor nulla eu urna tempor, ut pharetra nulla pretium.
        </p>
        <figcaption className="font-second_font font-light text-sm uppercase">
        PARAGRAPH TEXT, AKTIV GROTESK LIGHT, 18PT, SENTENCE CASE
        </figcaption>
      </figure>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a
          href={withPrefix("/downloads/3FO_Brand_Typography.pdf")}
          className="no-underline"
          download
        >
          <button className="btn" id="download-logo-mark">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
        Download typography sample and spec sheet PDF.
        </p>
      </div>
    </section>
  );
}

TypographySection.propTypes = {
  copyLink: PropTypes.func
};

export default TypographySection;
