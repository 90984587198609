import React from "react";
import PropTypes from 'prop-types';
import { withPrefix } from "gatsby"

// icons
import anchor from "../images/3FO_BG_Links.svg";
import anchor_yellow from "../images/3FO_BG_Links_yellow.svg";
// images
import primaryLogo from "../images/3FO_LOGO_primary.svg"
import logoSymbol from "../images/3FO_LOGO-MARK_primary.svg"
import colorLogo from "../images/3FO_LOGO_color.svg"
import greyLogo from "../images/3FO_LOGO_grey.svg"
import blackLogo from "../images/3FO_LOGO_black.svg"
import whiteLogo from "../images/3FO_LOGO_white.svg"
import colorSymbol from "../images/3FO_LOGO-MARK_color.svg"
import graySymbol from "../images/3FO_LOGO-MARK_grey.svg"
import blackSymbol from "../images/3FO_LOGO-MARK_black.svg"
import whiteSymbol from "../images/3FO_LOGO-MARK_white.svg"

function LogoSection({ copyLink }) {
  return (
    <section className="text-left mt-20 w-full" id="logo">
      <div className="bg-white sticky flex z-10 top-0">
        <h1 className="text-heading_color bg-white font-primary_font text-5xl inline-block pt-36 pb-1 sticky top-0" tabIndex="0">
        logo
        </h1>
        <img
            className="anchor-icon"
            onClick={() => copyLink("#logo")}
            src={anchor}
            onMouseEnter={e => e.currentTarget.src = anchor_yellow}
            onMouseLeave={e => e.currentTarget.src = anchor}  
            alt="copy link"
          />
      </div>  
      <p className="my-4 md:w-5/6" tabIndex="0">
      Our logo is the face of our brand identity, and is used in these two formats. The full logo in our brand yellow or in white on our yellow background should be used as often as possible. The logo mark or symbol should be used for social media avatars or for internal reference.
      </p>
      <h2 className="text-heading_color font-primary_font text-2xl inline-block mt-6" tabIndex="0">
      primary logos
      </h2>
      <div className="grid grid-cols-2 gap-8 my-4">
        <figure tabIndex="0">
          <img
            alt="primary logo for 3fo"
            className="block mx-auto w-full"
            src={primaryLogo}
          />
          <figcaption className="text-sm mt-1 uppercase">Master Logo</figcaption>
        </figure>
        <figure tabIndex="0">
          <img
            alt="logo symbol for 3fo"
            className="block mx-auto w-full"
            src={logoSymbol}
          />
          <figcaption className="text-sm mt-1 uppercase">Logo Symbol</figcaption>
        </figure>
      </div>
      <h2 className="text-heading_color font-primary_font text-2xl inline-block mt-6" tabIndex="0">
        color logos
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <img
            alt="color logo for 3fo"
            className="block mx-auto w-full"
            src={colorLogo}
          />
          <figcaption className="text-sm mt-1 uppercase">Color Logo</figcaption>
        </figure>
        <figure>
          <img
            alt="grey logo for 3fo"
            className="block mx-auto w-full"
            src={greyLogo}
          />
          <figcaption className="text-sm mt-1 uppercase">Grayscale Logo</figcaption>
        </figure>
        <figure>
          <img
            alt="black logo for 3fo"
            className="block mx-auto w-full"
            src={blackLogo}
          />
          <figcaption className="text-sm mt-1 uppercase">Black Logo</figcaption>
        </figure>
        <figure>
          <img
            alt="white logo for 3fo"
            className="block mx-auto w-full"
            src={whiteLogo}
          />
          <figcaption className="text-sm mt-1 uppercase">White Logo</figcaption>
        </figure>
      </div>
      <h2 className="text-heading_color font-primary_font text-2xl inline-block mt-6" tabIndex="0">
      color symbols
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <img
            alt="color symbol for 3fo"
            className="block mx-auto w-full"
            src={colorSymbol}
          />
          <figcaption className="text-sm uppercase mt-1">Color Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="gray symbol for 3fo"
            className="block mx-auto w-full"
            src={graySymbol}
          />
          <figcaption className="text-sm uppercase mt-1">Grayscale Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="black symbol for 3fo"
            className="block mx-auto w-full"
            src={blackSymbol}
          />
          <figcaption className="text-sm uppercase mt-1">Black Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="white symbol for 3fo"
            className="block mx-auto w-full"
            src={whiteSymbol}
          />
          <figcaption className="text-sm uppercase mt-1">White Symbol</figcaption>
        </figure>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a href={withPrefix('/downloads/3FO_LOGO_Pack.zip')} className="no-underline" download>
          <button className="btn" id="download-logo-mark">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download logo pack in SVG, EPS and PNG.</p>
      </div>
    </section>
  );
}

LogoSection.propTypes = {
  copyLink: PropTypes.func,

};

export default LogoSection;