import React, { useState } from "react";
import { withPrefix } from "gatsby"
import PropTypes from "prop-types";
import { useMediaQuery } from 'react-responsive'

// icons 
import anchor from "../images/3FO_BG_Links.svg";
import anchor_yellow from "../images/3FO_BG_Links_yellow.svg";
// images 
import  colorPhoto1 from "../images/3FO_Brand-Guide_Color_1.png";
import  colorPhoto2 from "../images/3FO_Brand-Guide_Color_2.png";
import  colorPhoto3 from "../images/3FO_Brand-Guide_Color_3.png";
// import Media from 'react-media';

function ColorSection({ copyLink }) {
  const [primary, setPrimary] = useState(false);
  const [primary2, setPrimary2] = useState(false);
  const [secondary, setSecondary] = useState(false);
  const [secondary2, setSecondary2] = useState(false);
  const [secondary3, setSecondary3] = useState(false);
  const [tertiary, setTertiary] = useState(false);
  const [tertiary2, setTertiary2] = useState(false);
  const [tertiary3, setTertiary3] = useState(false);
  const [tertiary4, setTertiary4] = useState(false);

  // stop color slider for medium screens
  const isMediumScreen = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <section className="text-left mt-20 w-full" id="color">
      <div className="bg-white sticky flex z-10 top-0">
        <h1 className="heading-1" tabIndex="0">
          color
        </h1>
        <img
          className="anchor-icon"
          onClick={() => copyLink("#color")}
          src={anchor}
          onMouseEnter={e => (e.currentTarget.src = anchor_yellow)}
          onMouseLeave={e => (e.currentTarget.src = anchor)}
          alt="copy link"
        />
      </div>
      <p
        className="font-second_font font-light text-2xl my-4 md:w-5/6"
        tabIndex="0"
      >
        Our brand colors are friendly and bold. Our primary colors, yellow and black, are used as often as possible and are supported by the minimal use of grey. Yellow is used most often in large background color fills while black is for copy and large headlines. Yellow is also used in headlines to add emphasis.
      </p>

      <h2
        className="text-heading_color font-primary_font text-2xl inline-block mt-6"
        tabIndex="0"
      >
        primary colors
      </h2>
      <div className="grid grid-cols-2 gap-4 my-4 font-second_font font-light text-2xl md:flex">
        {/* {data.primary_colors.map((item, key) => ( */}
          <div
          className={` ${
              isMediumScreen
                  ? "mobile_view"
                  : primary === true
                  ? "grow"
                  : primary !== true
                  ? "shrink"
                  : ""
            }`}
          >
            <figure className="relative">
              <div
                className="h-48"
                style={{ backgroundColor: '#fdc115' }}
              ></div>
              <div
                className="relative w-full text-black md:absolute top-0 left-0 md:text-white md:opacity-0 h-full display"
                onMouseEnter={() => {setPrimary(true), setPrimary2(false)}}
                onMouseLeave={() => setPrimary(true)}
              >
                <figcaption className="text-sm font-medium my-3 ml-2 uppercase">
                Yellow
                </figcaption>
                <div className="flex font-second_font font-light text-sm mt-1 leading-tight uppercase md:m-1 ">
                  <div className="grid grid-cols-2 gap-2 mb-2 w-4/5 w-2/3 md:mt-10 ml-2">
                    <div>HEX</div> <div>#fdc115</div>
                    <div>RGB</div> <div>253,193,21</div>
                    <div>CMYK</div> <div>0,25,99,0</div>
                    <div>PANTONE</div> <div>109</div>
                  </div>
                  <div className="w-1/5 md:w-1/3"></div>
                </div>
              </div>
            </figure>
          </div>
          <div
          className={` ${
              isMediumScreen
                ? "mobile_view"
                : primary2 === true
                ? "grow"
                : primary2 !== true
                ? "shrink"
                : ""
            }`}
          >
            <figure className="relative">
              <div
                className="h-48"
                style={{ backgroundColor: '#000000' }}
              ></div>
              <div
                className="relative w-full text-black md:absolute top-0 left-0 md:text-white md:opacity-0 h-full display"
                onMouseEnter={() => {setPrimary2(true), setPrimary(false)}}
                onMouseLeave={() => setPrimary2(true)}
              >
                <figcaption className="text-sm font-medium my-3 ml-2 uppercase">
                Black
                </figcaption>
                <div className="flex font-second_font font-light text-sm mt-1 leading-tight uppercase md:m-1 ">
                  <div className="grid grid-cols-2 gap-2 mb-2 w-4/5 w-2/3 md:mt-10 ml-2">
                    <div>HEX</div> <div>#000000</div>
                    <div>RGB</div> <div>0,0,0</div>
                    <div>CMYK</div> <div>0,0,0,0</div>
                    <div>PANTONE</div> <div>NEUTRAL BLACK</div>
                  </div>
                  <div className="w-1/5 md:w-1/3"></div>
                </div>
              </div>
            </figure>
          </div>
        {/* ))} */}
      </div>
      <h2
        className="text-heading_color font-primary_font text-2xl inline-block mt-6"
        tabIndex="0"
      >
        secondary colors
      </h2>
      <div className="grid grid-cols-2 gap-4 my-4 font-second_font font-light text-2xl md:flex">
        {/* {data.secondary_colors.map((item, key) => ( */}
        <div
            key={6}
            className={`${
              isMediumScreen
                ? "mobile_view"
                : tertiary === true
                ? "grow"
                : tertiary !== true
                ? "shrink"
                : ""
            }`}
          >
            <figure className="relative ">
              <div
                className="h-40"
                style={{ backgroundColor: '#F37032' }}
              ></div>
              <div
                className="text-black h-full md:absolute top-0 md:opacity-0 display"
                onMouseEnter={() => {setTertiary(true), setTertiary2(false), setTertiary3(false), setTertiary4(false)}}
                onMouseLeave={() => setTertiary(true)}
              >
                <figcaption className="text-sm font-medium uppercase m-2 md:mt-3">
                Red
                </figcaption>
                <div className="flex mb-2 font-second_font font-light text-sm leading-relaxed uppercase m-2">
                  <div className="flex flex-wrap md:mt-4">
                    <div className="w-1/2">HEX</div>{" "}
                    <div className="w-1/2">#F37032</div>
                    <div className="w-1/2">RGB</div>{" "}
                    <div className="w-1/2">243,112,50</div>
                    <div className="w-1/2">CMYK</div>{" "}
                    <div className="w-1/2">0,0,90,0</div>
                  </div>
                  <div className="w-1/5 md:w-1/3"></div>
                </div>
              </div>
            </figure>
          </div>
          <div
            className={`${
              isMediumScreen
                ? "mobile_view"
                : tertiary2 === true
                ? "grow"
                : tertiary2 !== true
                ? "shrink"
                : ""
            }`}
          >
            <figure className="relative ">
              <div
                className="h-40"
                style={{ backgroundColor: '#27B0C2' }}
              ></div>
              <div
                className="text-black h-full md:absolute top-0 md:opacity-0 display"
                onMouseEnter={() => {setTertiary2(true), setTertiary(false), setTertiary3(false), setTertiary4(false)}}
                onMouseLeave={() => setTertiary2(true)}
              >
                <figcaption className="text-sm font-medium uppercase m-2 md:mt-3">
                Blue
                </figcaption>
                <div className="flex mb-2 font-second_font font-light text-sm leading-relaxed uppercase m-2">
                  <div className="flex flex-wrap md:mt-4">
                    <div className="w-1/2">HEX</div>{" "}
                    <div className="w-1/2">#27B0C2</div>
                    <div className="w-1/2">RGB</div>{" "}
                    <div className="w-1/2">39,176,194</div>
                    <div className="w-1/2">CMYK</div>{" "}
                    <div className="w-1/2">72,8,23,0</div>
                  </div>
                  <div className="w-1/5 md:w-1/3"></div>
                </div>
              </div>
            </figure>
          </div>
          <div
            className={`${
              isMediumScreen
                ? "mobile_view"
                : tertiary3 === true
                ? "grow"
                : tertiary3 !== true
                ? "shrink"
                : ""
            }`}
          >
            <figure className="relative ">
              <div
                className="h-40"
                style={{ backgroundColor: '#A4CF57' }}
              ></div>
              <div
                className="text-black h-full md:absolute top-0 md:opacity-0 display"
                onMouseEnter={() => {setTertiary3(true), setTertiary(false), setTertiary2(false), setTertiary4(false)}}
                onMouseLeave={() => setTertiary3(true)}
              >
                <figcaption className="text-sm font-medium uppercase m-2 md:mt-3">
                Green
                </figcaption>
                <div className="flex mb-2 font-second_font font-light text-sm leading-relaxed uppercase m-2">
                  <div className="flex flex-wrap md:mt-4">
                    <div className="w-1/2">HEX</div>{" "}
                    <div className="w-1/2">#A4CF57</div>
                    <div className="w-1/2">RGB</div>{" "}
                    <div className="w-1/2">164,207,87</div>
                    <div className="w-1/2">CMYK</div>{" "}
                    <div className="w-1/2">40,0,85,0</div>
                  </div>
                  <div className="w-1/5 md:w-1/3"></div>
                </div>
              </div>
            </figure>
          </div>
          <div
            className={`${
              isMediumScreen
                ? "mobile_view"
                : tertiary4 === true
                ? "grow"
                : tertiary4 !== true
                ? "shrink"
                : ""
            }`}
          >
            <figure className="relative ">
              <div
                className="h-40"
                style={{ backgroundColor: '#EEE3CF' }}
              ></div>
              <div
                className="text-black h-full md:absolute top-0 md:opacity-0 display"
                onMouseEnter={() => {setTertiary4(true), setTertiary(false), setTertiary2(false), setTertiary3(false)}}
                onMouseLeave={() => setTertiary4(true)}
              >
                <figcaption className="text-sm font-medium uppercase m-2 md:mt-3">
                Cream
                </figcaption>
                <div className="flex mb-2 font-second_font font-light text-sm leading-relaxed uppercase m-2">
                  <div className="flex flex-wrap md:mt-4">
                    <div className="w-1/2">HEX</div>{" "}
                    <div className="w-1/2">#EEE3CF</div>
                    <div className="w-1/2">RGB</div>{" "}
                    <div className="w-1/2">238,227,2077</div>
                    <div className="w-1/2">CMYK</div>{" "}
                    <div className="w-1/2">6,8,18,0</div>
                  </div>
                  <div className="w-1/5 md:w-1/3"></div>
                </div>
              </div>
            </figure>
          </div>
        {/* ))} */}
      </div>
      <h2
        className="text-heading_color font-primary_font text-2xl inline-block mt-6"
        tabIndex="0"
      >
        color usage ratio
      </h2>
      <div className="flex mb-4">
        {/* {data.color_usage_ratio.map((item, key) => ( */}
          <div
            className="h-40"
            style={{ backgroundColor: '#fdc115', width: "40%" }}
          ></div>
          <div
            className="h-40"
            style={{ backgroundColor: '#000000', width: "40%" }}
          ></div>
          <div
            className="h-40"
            style={{ backgroundColor: '#ffffff', width: "40%", borderTop: 'solid 1px', borderBottom: 'solid 1px'  }}
          ></div>
          <div
            className="h-40"
            style={{ backgroundColor: '#b7b8b9', width: "2%"}}
          ></div>
           <div
            className="h-40"
            style={{ backgroundColor: '#27B0C2', width: "1%" }}
          ></div>
           <div
            className="h-40"
            style={{ backgroundColor: '#F37032', width: "1%" }}
          ></div>
           <div
            className="h-40"
            style={{ backgroundColor: '#A4CF57', width: "1%" }}
          ></div>
           <div
            className="h-40"
            style={{ backgroundColor: '#EEE3CF', width: "1%" }}
          ></div>
        {/* ))} */}
      </div>
      <h2
        className="text-heading_color font-primary_font text-2xl inline-block mt-6"
        tabIndex="0"
      >
        color in use
      </h2>
      <figure className="">
        <img
            alt=""
            className="block mb-1 mx-auto w-full"
            src={colorPhoto1}
          />
      </figure>
      <div className="grid grid-cols-2 gap-8 my-8">
          <figure className="">
            <img
                alt=""
                className="block mb-1 mx-auto w-full"
                src={colorPhoto2}
              />
          </figure>
          <figure className="">
            <img
                alt=""
                className="block mb-1 mx-auto w-full"
                src={colorPhoto3}
              />
          </figure>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a
          href={withPrefix("/downloads/3FO_Brand-Color_2023.pdf")}
          className="no-underline"
          download
        >
          <button className="btn" id="download-logo-mark">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
        Download color palette spec sheet PDF.
        </p>
      </div>
    </section>
  );
}

// ColorSection.propTypes = {
//   data: PropTypes.object,
//   copyLink: PropTypes.func
// };

export default ColorSection;
