import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";
import anchor from "../images/3FO_BG_Links.svg";
import anchor_yellow from "../images/3FO_BG_Links_yellow.svg";

// sections
import ConceptSection from "../components/concept-section";
import LogoSection from "../components/logo-section";
import ColorSection from "../components/color-section";
import TypographySection from "../components/typography-section";
import PhotographySection from "../components/photography-section";

  function IndexPage()  {
    // for component anchor-copy feature
  const [copyStatus, setCopyStatus] = useState(false);

  const copyLink = urlSection => {
    const url = window.location.origin;
    navigator.clipboard.writeText(`${url}/${urlSection}`);
    document.execCommand("copy");
    setCopyStatus(true);
  };

  useEffect(() => {
    if (copyStatus) {
      setTimeout(() => {
        setCopyStatus(false);
      }, 4000);
    }
  });
    return (
    <Layout>
      <SEO
        keywords={[`brand`, `3fo`, `300feetout`, `internal`]}
        title="Brand"
      />

      <div
        className={`bg-primary_color w-full p-2 font-primary_font text-white fixed z-40 transform -translate-y-16 ${copyStatus &&
          "alert"}`}
      >
        <p className={`opacity-0 ${copyStatus && "opacity-100"}`}>
          Copied link to clipboard!
        </p>
      </div>

      {/* Intro */}
      <section className="text-left pt-24 md:w-5/6" id="intro">
        <div className="bg-white sticky z-10 flex top-0">
          <h1
            className="text-heading_color font-primary_font text-5xl inline-block pt-36 md:pt-12 pb-1 sticky top-0"
            tabIndex="0"
          >
            brand guidelines
          </h1>
          <img
            className="anchor-icon"
            onClick={() => copyLink("#intro")}
            src={anchor}
            onMouseEnter={e => (e.currentTarget.src = anchor_yellow)}
            onMouseLeave={e => (e.currentTarget.src = anchor)}
            alt="copy link"
          />
        </div>
        <p className="my-4" tabIndex="0">
          Welcome to our brand guidelines, a digital tool we’ve created to help
          make it a little easier for you to maintain our brand.
        </p>
        <p className="my-4" tabIndex="0">
          Here you’ll find the foundational elements that create our brand
          identity. Consistency is key in keeping our brand presence strong.
          Consistent and repetitive usage of these elements will create lasting
          recognition in our noisy world.
        </p>
      </section>
      {/* Concept */}
      <ConceptSection copyLink={copyLink} />

      {/* Logo section */}
      <LogoSection copyLink={copyLink} />

      {/* Color Section */}
      <ColorSection copyLink={copyLink} />

      {/* Typography Section */}
      <TypographySection copyLink={copyLink} />

      {/* Photography Section */}
      <PhotographySection copyLink={copyLink}  />
    </Layout>
    )
  };

IndexPage.propTypes = {
  copyLink: PropTypes.func
};

export default IndexPage;
