import React from "react";
import PropTypes from "prop-types";
import anchor from "../images/3FO_BG_Links.svg";
import anchor_yellow from "../images/3FO_BG_Links_yellow.svg";

/* jshint ignore:start */
const ConceptSection = ({ copyLink }) => {
  return (
    <section className="text-left" id="concept">
      <div className="bg-white sticky flex z-10 top-0">
        <h1
          className="text-heading_color font-primary_font text-5xl inline-block pt-36 pb-1 sticky top-0"
          tabIndex="0"
        >
          concept
        </h1>
        <img
          className="anchor-icon"
          onClick={() => copyLink("#concept")}
          src={anchor}
          onMouseEnter={e => (e.currentTarget.src = anchor_yellow)}
          onMouseLeave={e => (e.currentTarget.src = anchor)}
          alt="copy link"
        />
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        We design. And develop. Together. This true integration sparks
        one-of-a-kind, immersive brand experiences that push past the sea of
        sameness and dare people to connect.
      </p>
      <h2
        className="text-heading_color font-primary_font text-2xl inline-block mt-6"
        tabIndex="0"
      >
        values
      </h2>
      <p className="text-lg md:w-5/6 my-4" tabIndex="0">
      Our brand values are the beliefs that we stand for. They serve as the compass that guides our brand story, actions, behaviors, and decision-making process. Our team lives by these values, are recognized for reflecting them, and when decisions needs to be made we refer to them often.
      </p>
      <div className="font-primary_font text-primary_color bg-s1 grid grid-cols-2 pr-4 md:pr-20 pl-4 pt-8 pb-8 my-4 md:pl-8">
        {/* {data.values.two_column_brand_text.map((item, key) => ( */}
          <p className="" tabIndex="0">
          driven
          </p>
          <p className="" tabIndex="0">
          sincere
          </p>
          <p className="" tabIndex="0">
          committed
          </p>
          <p className="" tabIndex="0">
          fearless
          </p>
          <p className="" tabIndex="0">
          people-centric
          </p>
        {/* ))} */}
      </div>
      <h2
        className="text-heading_color font-primary_font text-2xl inline-block mt-6"
        tabIndex="0"
      >
        mission
      </h2>
      <p
        className="font-primary_font text-primary_color bg-s1 pr-5 md:pr-20 pl-8 pt-8 pb-8 my-4"
        tabIndex="0"
      >
        We’re an offbeat, no-holds-barred kind of creative studio — giving it all we’ve got, with zero reservations. We design. And develop. Together. This true integration sparks one-of-a-kind, immersive brand experiences that push past the sea of sameness and dare people to connect.
      </p>
      <h2
        className="text-heading_color font-primary_font text-2xl inline-block mt-6"
        tabIndex="0"
      >
        personality
      </h2>
      <p className="text-lg md:w-5/6 my-4" tabIndex="0">
      Our brand personality are a set of human characteristics our brand reflects. They are communicated through visuals, tone of voice, and even customer service.
      </p>
      <div className="font-primary_font text-primary_color bg-s1 grid grid-cols-2 pr-4 md:pr-20 pl-4 pt-8 pb-8 my-4 md:pl-8">
        {/* {data.personality.two_column_personality_text.map((item, key) => ( */}
          <p className="" tabIndex="0">
          courageous
          </p>
          <p className="" tabIndex="0">
          insightful
          </p>
          <p className="" tabIndex="0">
          candid
          </p>
          <p className="" tabIndex="0">
          offbeat
          </p>
          <p className="" tabIndex="0">
          fun
          </p>
          <p className="" tabIndex="0">
          fierce
          </p>
          <p className="" tabIndex="0">
          passionate
          </p>
          <p className="" tabIndex="0">
          loyal
          </p>
          <p className="" tabIndex="0">
          resolute
          </p>
        {/* ))} */}
      </div>
      <h3
        className="text-heading_color font-primary_font text-2xl inline-block mt-6"
        tabIndex="0"
      >
        tagline
      </h3>
      <p className="text-lg md:w-5/6 my-4" tabIndex="0">
      Our tagline acts as a verbal logo. It tells people what we do simply and memorably.
      </p>
      <p className="font-primary_font text-second_color bg-s1 text-4xl pr-5 md:pr-20 pl-8 pt-8 pb-8 mt-4">
      connecting brands
        <span className="text-primary_color"> with people.</span>
      </p>
    </section>
  );
};

ConceptSection.propTypes = {
  copyLink: PropTypes.func
};

/* jshint ignore:end */
export default ConceptSection;
