import React from "react";
import PropTypes from 'prop-types';
import { withPrefix } from "gatsby"

// icons
import anchor from "../images/3FO_BG_Links.svg";
import anchor_yellow from "../images/3FO_BG_Links_yellow.svg";

// images 
import photo1 from "../images/3FO_PHOTOGRAPHY_1.jpg";
import photo2 from "../images/3FO_PHOTOGRAPHY_2.jpg";
import photo3 from "../images/3FO_PHOTOGRAPHY_3.jpg";
import photo4 from "../images/3FO_PHOTOGRAPHY_4.jpg";
import photo5 from "../images/3FO_PHOTOGRAPHY_5.jpg";
import photo6 from "../images/3FO_PHOTOGRAPHY_6.jpg";

// var he = require('he')

function PhotographySection({ copyLink }) {

  return (
    <section className="text-left mt-20 w-full" id="photography">
      <div className="bg-white sticky flex z-10 top-0">
        <h1 className="heading-1" tabIndex="0">
        photography
        </h1>
        <img
            className="anchor-icon"
            onClick={() => copyLink("#photography")}
            src={anchor}
            onMouseEnter={e => e.currentTarget.src = anchor_yellow}
            onMouseLeave={e => e.currentTarget.src = anchor}   
            alt="copy link"
          />
      </div>
      <p className="font-second_font font-light  text-2xl my-4 md:w-5/6" tabIndex="0">
      Our brand photography is fun, colorful, and authentically us. We share our culture with our customers and let our personalities shine. Photography should reflect this attitude, make an impact, have a clear focus, and always reflect our values.
      </p>

      <h2 className="text-heading_color font-primary_font text-2xl inline-block my-6" tabIndex="0">
          photography art direction
      </h2>
      <figure className="">
        <img
            alt=""
            className="block mb-1 mx-auto w-full"
            src={photo1}
          />
        <figcaption className="font-second_font font-light text-sm uppercase">WE’RE PROUD OF OUR BAY AREA LOCATIONS AND REINFORCE OUR BRAND COLOR WHEN POSSIBLE.</figcaption>
      </figure>
      <div className="grid grid-cols-2 gap-8 my-8">
        {/* {data.photography_secondary.map((item, key) => */}
          <figure className="">
            <img
                alt=""
                className="block mb-1 mx-auto w-full"
                src={photo2}
              />
            <figcaption className="font-second_font font-light text-sm uppercase" >CLEAR FOCUS WITHIN THE COMPOSITION AND AN IMPACTFUL CROP.</figcaption>
          </figure>
          <figure className="">
            <img
                alt=""
                className="block mb-1 mx-auto w-full"
                src={photo3}
              />
            <figcaption className="font-second_font font-light text-sm uppercase" >FUN AND REFLECTIVE OF OUR STUDIO SPACE.</figcaption>
          </figure>
        {/* )} */}
      </div>
      <div className="grid grid-cols-3 gap-8 mb-4">
        {/* {data.photography_tertiary.map((item, key) => */}
          <figure className="">
            <img
                alt=""
                className="block mb-1 mx-auto w-full"
                src={photo4}
              />
            <figcaption className="font-second_font font-light text-sm uppercase">SOFT FOCUS SURROUND HELPS ADD FOCUS.</figcaption>
          </figure>
          <figure className="">
            <img
                alt=""
                className="block mb-1 mx-auto w-full"
                src={photo5}
              />
            <figcaption className="font-second_font font-light text-sm uppercase">WE SHOULD’VE TRADEMARKED AUTHENTIC IN 1997.</figcaption>
          </figure>
          <figure className="">
            <img
                alt=""
                className="block mb-1 mx-auto w-full"
                src={photo6}
              />
            <figcaption className="font-second_font font-light text-sm uppercase">DRIVEN AND GUTSY.</figcaption>
          </figure>
        {/* )} */}
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a href={withPrefix("/downloads/3FO_PHOTOGRAPHY_Starter-Pack.zip")} className="no-underline" download>
          <button className="btn" id="download-logo-mark">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download photography starter pack.</p>
      </div>
    </section>
  );
}

PhotographySection.propTypes = {
  copyLink: PropTypes.func,

};

export default PhotographySection;